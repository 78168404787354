<template>
  <!-- 照片燈箱 -->
  <vue-easy-lightbox :moveDisabled='true' :visible="visible" :imgs="testImg" :index="index" @hide="showImg(false,'','')">
    <template v-if="testImg.length>1" v-slot:prev-btn="{ prev }">
      <div @click="prev" class="btn__prev">
        <svg class="carousel__icon" viewBox="0 0 24 24" role="img">
          <title>arrowLeft</title>
          <path d="M16.3,6.3L10.8,12l5.5,5.6c0.6,0.6,0.6,1.4,0,2l0,0c-0.6,0.6-1.4,0.6-2,0L7.7,13c-0.6-0.6-0.6-1.4,0-2l6.6-6.6
	c0.6-0.6,1.4-0.6,2,0l0,0C16.9,4.9,16.9,5.8,16.3,6.3z"/>
        </svg>
      </div>
    </template>
    <template v-if="testImg.length>1" v-slot:next-btn="{ next }">
      <div @click="next" class="btn__next">
        <svg class="carousel__icon" viewBox="0 0 24 24" role="img">
          <title>arrowRight</title>
          <path d="M8,17.6l5.5-5.6L8,6.4c-0.6-0.6-0.6-1.4,0-2l0,0c0.6-0.6,1.4-0.6,2,0l6.6,6.6c0.6,0.6,0.6,1.4,0,2L10,19.5
	c-0.6,0.6-1.4,0.6-2,0l0,0C7.4,19,7.4,18.2,8,17.6z"/>
        </svg>
      </div>
    </template>
    <template v-slot:close-btn="{ close }">
      <button @click="close" class="close position-absolute m-0 p-0"></button>
    </template>
  </vue-easy-lightbox>

  
  <div class="vote row justify-content-center pt-3 pt-lg-4">
    
    <!-- 返回頂部 -->
    <div class="goTop">
      <img src="@/assets/images/btn_gotop.png" alt="" class="w-100">
    </div> 

    <!-- loading -->
    <PagesLoading v-show="isPagesLoading"/>

    <!-- [Left] fixd logo  -->
    <transition name="fade">
      <div v-show="$route.name!='index' && delay" class="page_kv_fixed">
        <img src="@/assets/images/img_kv_head_page.png" class="w-100" alt="">
      </div>
    </transition>
    
    <!-- 上方文字區塊 -->
    <div v-if="!isPagesLoading" class="position-relative col-xl-9 text-center" data-aos="fade-up" data-aos-duration="1500" data-aos-anchor-placement="top-bottom">
      <img v-if="deviceMode != 'mobile'" class="vote__title d-block mx-auto mb-3" src="@/assets/images/vote/img_title.png" alt="八景4.0全民票選抽大獎">
      <img v-else class="vote__title mb-4" src="@/assets/images/vote/img_title_s.png" alt="八景4.0全民票選抽大獎">
      <p>淡水八景4.0壓軸活動<br class="d-md-none">「全民票選抽大獎」，已經開跑嘍！<br>
      立馬投下你最有力的選票，<br class="d-md-none">點亮心中最美的淡水秘境。<br>
      共邀你的親朋好友加入你的美景應援團，<br class="d-md-none">一起投出最美好、最有感的淡水景點吧！</p>

      <!-- 前往淡水繽紛八景 -->
      <router-link to="8views" class="goNewScene position-absolute">
        <img src="@/assets/images/vote/btn_new-4.png" alt="" class="w-100">
      </router-link>
    </div>

    <div v-show="!isPagesLoading" class="col-xl-9" data-aos="fade-up" data-aos-duration="1500" data-aos-anchor-placement="top-bottom">
    <!-- 地圖 -->
    <transition name="fadeInUp">
      <!-- <div  class="map"> -->
      <div class="map">
      <!-- 預設 -->
      <transition name="bounce" mode="out-in">
        <inline-svg v-show="clickMap ==''" class="map__all" :src="require('../assets/images/vote/map.svg')"/>
      </transition>
      
      <!-- 詳細地圖 -->
      <transition name="bounce" mode="out-in">
        <inline-svg v-if="clickMap != ''" class="map__dist--click w-100" :src="require('../assets/images/vote/map_'+clickMap+'_click.svg')" :class="{'active': clickMap!=''}" />
      </transition>
      <!-- back -->
      <transition name="bounce" mode="out-in">
        <img v-if="clickMap != ''" @click="hoverMap=''; clickMap=''" class="map__back" src="@/assets/images/vote/btn_back.png">
      </transition>
      </div>
    </transition>
    </div>

    <!-- Filter & intro -->
    <transition name="fadeInUp">
      <div class="subNav col-xl-9 flex-column flex-sm-row justify-content-between mb-3 my-md-3" v-show="!isPagesLoading" ref="clickaway">
        <ul class="subNav__left list-unstyled text-white mb-0 order-12 order-sm-1">
          <li @click="showToggle = !showToggle">
            <button class="btn btn-danger dropdown font-weight-bold text-left text-white" :class="{'dropdown--active':showToggle}">
              <span>{{defaultScene}}</span>
              <svg class="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                <polygon points="11.63 5.19 8.01 8.44 4.37 5.18 3.2 6.51 8.01 10.81 12.8 6.51 11.63 5.19"/>
              </svg>
            </button>
          </li>

          <transition name="toggle">
            <li v-if="showToggle" class="dropdown__item">
              <ul class="list-unstyled">
                <li v-if="defaultScene!='預設排列'" @click="defaultScene ='預設排列'; ReadData(); showToggle = false">
                  預設排列
                </li>
                <li @click="defaultScene = '人氣TOP 10景點'; sortList('Total_HtoL'); showToggle = false" >人氣TOP 10景點</li>
                <li @click="defaultScene = '票數高到低'; sortList('HtoL'); showToggle = false" >票數高到低 ↓</li>
                <li @click="defaultScene = '票數低到高'; sortList('LtoH'); showToggle = false" >票數低到高 ↑</li>
                <li @click="defaultScene = '編號高到低'; sortList('Num_HtoL'); showToggle = false" >編號高到低 ↓</li>
                <li @click="defaultScene = '編號低到高'; sortList('Num_LtoH'); showToggle = false" >編號低到高 ↑</li>
              </ul>
            </li>
          </transition>
        </ul>
        <div class="subNav__right font-weight-bold mb-2 mb-sm-0 order-1 order-sm-12">
          <!-- <router-link to="/intro#part3_4" class="btn btn-danger mr-2 py-0 px-1" role="button" aria-pressed="true">
            <img src="@/assets/images/vote/btn_award.png" style="height:36px" alt="獎項說明">
          </router-link> -->
          <div @click="showModal=true" class="btn btn-danger font-weight-bold mr-2" role="button" aria-pressed="true">TOP 10評選結果
          </div>
          <router-link to="/intro#part3" class="btn btn-secondary font-weight-bold" role="button" aria-pressed="true">活動辦法
          </router-link>
        </div> 
      </div>
    </transition>    

    <!-- card -->
    <ul class="col-xl-9 list-unstyled">      
      <template v-for="(item, index) in VoteListDisplay" :key="item.ID">
        
        <li class="card mb-4" data-aos="fade-up" data-aos-duration="1500" data-aos-anchor-placement="top-bottom" :id="item.ID">
          
          <div v-if="TopTen.indexOf(item.ID)!==-1" class="position-absolute vote-show">
            <img src="@/assets/images/vote/badge_top10.png" title="Top 10" class="w-100" >      
          </div>

          <div class="row">
            <!-- photo -->
            <div class="col-lg-7 mb-4 mb-lg-auto" v-if="item.Imgs">
              <carousel v-if="item.Imgs.length==1">
                <slide v-for="(photo,index) in item.Imgs" :key="index" @click="showImg(true,index,item.Imgs)">
                  <img loading="lazy" v-lazy="{src: photo, loading: LoadingImg(photo), error:'http://hilongjw.github.io/vue-lazyload/dist/404.jpg'}">
                </slide>
              </carousel>
              <carousel v-else>
                <slide v-for="(photo,index) in item.Imgs" :key="index" @click="showImg(true,index,item.Imgs)" >
                  <img loading="lazy" v-lazy="{src: photo, loading: LoadingImg(photo), error:'http://hilongjw.github.io/vue-lazyload/dist/404.jpg'}">
                </slide>
                <template #addons>
                  <navigation />
                  <pagination class="mb-0 pl-0"/>
                </template>
              </carousel>
            </div>
            <div class="col-lg-7 mb-4 mb-lg-0" v-else>
              <carousel>
                <slide>
                  <img src="https://via.placeholder.com/400x300">
                </slide>
              </carousel>
            </div>
            <!-- description -->
            <div class="col-lg-5 d-flex flex-column">
              <div class="des__head">
                <div class="d-flex align-items-end">
                  <h2 class="h4 font-weight-bold mb-1">{{item.GroupTitle!=''?item.GroupTitle:removeHtml(item.TitleAlias)}}<span class="card__num ml-1">{{item.PICID}}</span>
                  </h2>           
                </div>
                <span class="d-flex align-items-center small mb-3">
                  <span :class="item.Classification.toLowerCase()" class="icon__class mr-1"></span>  
                  {{SceneCfg.Classification[item.Classification]}}
                </span>
              </div>
              <div class="des__body pb-3">
                <p class="mb-0">{{item.GroupDes!=''?item.GroupDes:item.Des}}</p>
                <span class="more position-relative pt-3" :class="{'more--active': item.readMore}">
                  <a v-if="!item.readMore" @click="item.readMore = !item.readMore; DesClick(index)" class="btn w-100">閱讀更多</a>
                  <a v-else @click="item.readMore = !item.readMore" class="btn w-100">收合</a>               
                </span>
              </div>
              <div class="des__foot d-flex flex-column flex-md-row align-items-md-end">
                <ul class="flex-grow-1 list-unstyled h6 font-weight-normal mb-0">
                  <li v-if="item.Address!=''">地址：{{item.Address}}</li>
                  <li v-if="item.TEL!=''">電話：{{item.TEL}}</li>
                </ul>
              </div>            
            </div>            
          </div>

          <!-- 投票結果 -->
          <div class="card__vote row flex-column flex-md-row align-items-center mt-2 mt-lg-4">
          
            <div class="col-lg-6 col-xl-7 d-flex flex-column flex-md-row justify-content-center  justify-content-lg-start align-items-center mb-2 mb-lg-0">
              <!-- 評審 -->
              <!-- <div class="card__vote__num--1 text-danger font-weight-bold mr-md-3">{{item.RevVote}}</div> -->
              <!-- 民眾 -->
              <!-- <div class="card__vote__num--2 text-danger font-weight-bold">{{toCurrency(item.Vote_Count)}}</div> -->
            </div>

            <!-- <hr class="d-lg-none my-2" style="width: 90%; border-top: 3px solid #e0cf7c;"> -->

            <div class="col-lg-6 col-xl-5 d-flex flex-column flex-lg-row">
              <div class="card__vote__num align-self-center text-danger font-weight-bold mb-2 mb-lg-0">
                {{toCurrency(item.Vote_Count)}}
              </div>

              <div class="d-flex align-self-center align-items-center ml-lg-auto">
                <a :href="'https://facebook.com/sharer/sharer.php?u=https://dev.2021tamsui.tw/vote/'+ item.ID + '/f'" class="fixed-link__icon">
                  <img src="@/assets/images/icon_fb.svg" alt="">
                </a>
                <a :href="'https://social-plugins.line.me/lineit/share?url=https://dev.2021tamsui.tw/vote/'+ item.ID + '/l'" class="fixed-link__icon ml-3 ml-md-2">
                  <img src="@/assets/images/icon_line.svg" alt="">
                </a>
              </div>
            </div>
            
          </div>
        </li>

      </template>
    </ul>
    
    <!-- recommend modal -->
    <transition name="fade">
      <div v-if="showModal" v-bind:class="{ show: showModal }" class="modal modal__recommend" @click.self="showModal = false">
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header position-relative">
							<h3 class="font-weight-bold">TOP 10 評選結果</h3>
							<button @click="showModal = false" type="button" class="close position-absolute m-0 p-0"></button>
						</div>
            <div class="modal-body row justify-content-center text-secondary">
              <div class="col-md-11 col-lg-9 text-center">

                <h5 class="font-weight-bold">一、計分方式 : </h5>
                <!-- <h6 class="font-weight-bold" style="line-height: 1.35;">本票選活動計分方式為 : </h6> -->
                <p>網路票選占50%、評選委員會評分占50%，<br class="d-none d-lg-block">
                並依照下列計分方式加總後為景點之累積計分。</p>

                <h6 class="font-weight-bold mt-4">評審計分方式 :</h6>
                <p class="mb-2">評審分數占總分50%，每位評審採不記名投票，<br class="d-none d-lg-block">
                每位可投1-8票，最高票為50分。</p>
                <div class="d-flex justify-content-center text-left">
                  <span>例 :</span>                   
                  <ul class="list-unstyled text-nowrap ml-2 mb-0">
                    <li>10票 → 50分</li>
                    <li>&nbsp;&nbsp;8票 → 40分(8/(10/50))</li>
                    <li>&nbsp;&nbsp;5票 → 25分(5/(10/50))</li>
                  </ul>
                </div>

                <h6 class="font-weight-bold mt-3">網路投票計分方式 :</h6>
                <p class="mb-2">以網路最高票設定為滿分，最高分設定為50分。</p>
                <div class="d-flex justify-content-center text-left">
                  <span>例 :</span>                   
                  <ul class="list-unstyled text-nowrap ml-2 mb-0">
                    <li>9500票 → 50分</li>
                    <li>6500票 → 34.2分(6500/(9500/50))</li>
                    <li>5600票 → 29.5分(5600/(9500/50))</li>
                    <li>3560票 → 18.7分(3560/(9500/50))</li>
                  </ul>
                </div>

                <h5 class="font-weight-bold mt-5">二、評審委員會決議 : </h5>
                <p>經各位評審指導委員會研討後，共同決議因A10福容大飯店＆漁人碼頭情人塔、A41淡水漁人碼頭、A22淡海情人橋＆漁人舞台等位於淡水出海口均為最美欣賞夕陽最優質方位，及情人約會，親子休憩的好地點，均列為一大景，故再加入A15雲門劇場及A06齊柏林空間並列為本次2021淡水八景4.0人氣TOP10景點。</p>
                <p><a href="https://drive.google.com/file/d/1Pf-CMnMQ9q1tG0sEqCKwUKhynjbzSYq-/view" target="_blank">各景點分數列表</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div v-if="showModal" :class="{ show: showModal }" class="modal__backdrop d-flex justify-content-center align-items-center">
      </div>
    </transition>

  </div>  
</template>

<script>
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import $ from 'jquery';
import InlineSvg from 'vue-inline-svg';
import PagesLoading from '@/components/PagesLoading.vue'
import 'vue3-carousel/dist/carousel.css';

// var SI=0;
export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    InlineSvg,
    PagesLoading
  },
  data() {
    return {
      delay:false, 
      defaultScene: '預設排列',
      showToggle: false,
      showModal: false,
      isPagesLoading: true,
      isCounting: false,
      VoteList:[],
      VoteListAll:[],
      VoteListDisplay:null,
      SceneList:null,
      SceneCfg:null,
      readMore:false,
      hoverMap:'',
      clickMap:'',     
      visible: false,
      index: 0, // default: 0
      testImg: [],

      maxVote_Count:'',
      maxRevVote:'',
      TopTen: []
    }
  },  
  /* Init */
  created() {
    this.ReadData();    
  },
  mounted() {
    document.title = "2021淡水八景4.0-全民票選抽大獎";
    document.querySelector('meta[property="og:title"]').setAttribute("content", '2021淡水八景4.0「全民票選抽大獎」，立馬投下你最有力的選票，一起找出淡水最佳旅遊景點。');
    document.querySelector('meta[name="description"]').setAttribute("content", '立馬投下你最有力的選票，還有機會將iPhone 12、Galaxy Watch Active2、SONY可攜式藍牙喇叭、飯店住宿券等超值好禮帶回家喔！');
    document.querySelector('meta[property="og:description"]').setAttribute("content", '立馬投下你最有力的選票，還有機會將iPhone 12、Galaxy Watch Active2、SONY可攜式藍牙喇叭、飯店住宿券等超值好禮帶回家喔！');
    document.querySelector('meta[property="og:image"]').setAttribute("content",require('@/assets/images/vote/img_cover_part3.jpg'));
    window.addEventListener('click', this.handleClickaway); 
    window.addEventListener('click', this.clickDist);   
    
  },
	beforeUnmount() {
    window.removeEventListener('click', this.handleClickaway);
  },
  updated(){  
    var tt = this;
    $("#dist-1,#dist-2,#dist-3,#dist-4,#dist-5,#dist-6,#dist-7,#dist-8").on( "mouseenter", function() {
      tt.hoverMap=$(this).attr('id').split("-")[1];
    }).on( "click", function() {
      tt.clickMap=tt.hoverMap;
    });

    // var VDP=this.VoteListDisplay;

    $(window).scroll(function(){
      if($(window).scrollTop()>=1000){
          $(".goTop").fadeIn(300).click(function() {
            $('html, body').stop(true, false).animate({
                scrollTop: 0
            }, 'slow');
            return false;
          });
      } else{
          $(".goTop").fadeOut(300);
      }      
      /*var scrollTop = $(this).scrollTop();//已經滾動到上面的頁面高度
      var scrollHeight = $(document).height();//頁面高度
      var windowHeight = $(this).height();//瀏覽器窗口高度
      if(windowHeight*SI<=scrollTop || scrollTop + windowHeight == scrollHeight){ //捲動達一個窗口高度時觸發
        for(var f=SI*3;f<=SI*3+2;f++){
          console.log(f,VDP[f].Vote_G_P);
        }
        SI++;
      }*/
    });

    for(var i=0;i<=$('.des__body').length-1;i++){
      let cardImgH = $( '.carousel:eq('+i+')' ).height();
      let cardHeadH = $( '.des__head:eq('+i+')' ).height();
      let cardBodyH = $( '.des__body p:eq('+i+')' ).height();
      let cardFootH = $( '.des__foot:eq('+i+')' ).height();
      let cardContent = cardImgH - cardHeadH - cardFootH;
      let windowSize = $(window).width();
      //console.log(i,cardBodyH,cardContent,(cardBodyH > cardContent));
      if(cardBodyH+20 > cardContent && windowSize > 991){
        $( '.des__body:eq('+i+')' ).css("max-height",cardContent+'px');
        $( '.des__body .more:eq('+i+')' ).show();
      } else if (cardBodyH+54 > cardImgH && windowSize < 768){
        $( '.des__body:eq('+i+')' ).css("max-height",cardImgH-60+'px');
        $( '.des__body .more:eq('+i+')' ).show();
      }
    }
  },
  watch: {
    showModal() {
      this.showModal ? document.body.classList.add("modal-open") : document.body.classList.remove("modal-open");
    },
  },
  /* Function */
  methods: {
    // 載入資料
    ReadData:function(){
      var FD = new FormData();
          FD.append('mode', 'sct');
          FD.append('data', '{"class":"Scene","lang":"zh-TW","VoteCount":true,"Imgs":true}');

      this.axios({
          method: 'post',
          url: 'RApi',
          data: FD,
          headers: {'Content-Type': 'multipart/form-data' }
      })
      .then(response => {
          this.SceneCfg=response.data;
          this.VoteList=response.data.data.filter(item => item.TitleAlias!='');
          
          //組合景點群照片
          let a;
          let SceneList=response.data.data.filter(item => item._Status_=='3'||item._Status_=='4'); //93
          this.VoteList.forEach(function(item){ //41
            let sjArr = item.SceneJoin.split(',');
            sjArr.pop();

            if(sjArr.length>0){
              a=sjArr.map(function(sji){
                if(sji!=''){
                  let sjitmp=SceneList.filter(item => item.Title==sji)[0];
                  if(sjitmp!=undefined){
                    if(sjitmp.Imgs.filter(item => item.UploadStatus=='7').length){
                      // let s=sjitmp;
                      sjitmp=sjitmp.Imgs.filter(item => item.UploadStatus=='7').map(function(t){return t.Img});
                      // console.log(s.Title,sjitmp);
                    }else{
                      sjitmp=new Array(sjitmp.Imgs.filter(item => item.UploadStatus=='3'||item.UploadStatus=='4')[0].Img);
                    }
                    return sjitmp;
                  }
                }
              });  
              if(a.length>1){
                item.Imgs = [].concat.apply([], a); //將array往上提一層&合併
              } else{
                item.Imgs=a[0];
              }
              // console.log(index,item.Title,a[0]);
            }
          });
         
          // 最高民眾票數
          this.maxVote_Count = Math.max.apply(Math, this.VoteList.map(function(o) { return o.Vote_Count; }))
          // 最高評審票數
          this.maxRevVote = Math.max.apply(Math, this.VoteList.map(function(o) { return o.RevVote; }))

          // 傳入hashtag( ID )
          if(this.$route.params.ID){ 
            this.defaultScene = '預設排序';
            let hashtag = this.$route.params.ID;            
            if(hashtag==0){
              document.querySelector('meta[property="og:title"]').setAttribute("content", '2021淡水八景4.0「全民票選抽大獎」');
              document.querySelector('meta[property="og:description"]').setAttribute("content", '立馬投下你最有力的選票，還有機會將iPhone 12、Galaxy Watch Active2、SONY可攜式藍牙喇叭、飯店住宿券等超值好禮帶回家喔！');
              document.querySelector('meta[property="og:image"]').setAttribute("content",require('@/assets/images/vote/img_cover_part3.jpg'));
            }else{
              let hashtagMeta = response.data.data.filter(t => t.ID == hashtag);
              let shareTitle = hashtagMeta[0].GroupTitle!=''?hashtagMeta[0].GroupTitle:this.removeHtml(hashtagMeta[0].TitleAlias);
              let focusItem = this.VoteList.filter(item =>item.ID==hashtag);
              this.VoteListDisplay = this.VoteList.filter(item =>item.ID!=hashtag);
              this.VoteListDisplay.unshift(focusItem[0]);
              // 換metadata
              document.title =  "2021淡水八景4.0全民票選應援景點："+shareTitle;
              document.querySelector('meta[property="og:title"]').setAttribute("content", "2021淡水八景4.0全民票選應援景點："+shareTitle);
              document.querySelector('meta[property="og:description"]').setAttribute("content", '最強的美景應援團，集合啦！大家都來支持我最愛的景點吧！');
              if(this.$route.params.share=='f'){
                  let StrA=hashtagMeta[0].Imgs[0].split("/");
                  let Url='';
                  StrA.forEach(function(item,index){if(index<StrA.length-1){Url=Url+item+'/';}});
                  document.querySelector('meta[property="og:image"]').setAttribute("content",Url+'vote/'+StrA[StrA.length-1].split(".")[0]+'.jpg');
              } else if(this.$route.params.share=='l'){
                  document.querySelector('meta[property="og:image"]').setAttribute("content", hashtagMeta[0].Imgs[0]);
              }
            }
          } else if(this.$route.hash) {
            let cardID = this.$route.hash;
            setTimeout(function(){
              $('html, body').animate({
                scrollTop: $(cardID).offset().top - 100
              }, 2000);
            },500);
          }

          this.isPagesLoading = false;
          this.delay=true;

          // Top 10的ID
          let maxV = this.maxVote_Count;
          let maxR = this.maxRevVote;
          this.TopTen = this.VoteList.sort(function(a, b) {
            return ((b.Vote_Count*50)/maxV + (b.RevVote!==''?(b.RevVote*50)/maxR:0)) - ((a.Vote_Count*50)/maxV + (a.RevVote!==''?(a.RevVote*50)/maxR:0));
          }).slice(0, 12)
          this.TopTen = this.TopTen.map(function(item){
            return item.ID
          })

          //隨機排序(預設)
          this.VoteListDisplay= this.VoteList.sort(function(){return 0.5 - Math.random()});
        });
    },
    LoadingImg: function(Str=null){
      let StrA=Str.split("/");
      let Url='';
      StrA.forEach(function(item,index){if(index<StrA.length-1){Url=Url+item+'/';}});
      return Url+'min/'+StrA[StrA.length-1].split(".")[0]+'.jpeg';
    },

    // 燈箱 lightbox
    showImg:function(Box,index,Item) {
      if(Box!=false){
        this.index = index;
        this.testImg = Item;
        // Item.forEach((Items,i) => this.testImg.push(Items[i]));
        document.body.classList.add("modal-open");
        this.visible = true;
      }else{
        document.body.classList.remove("modal-open");
        this.visible = false;
      }
    },

    // 閱讀更多
    DesClick:function(e){
      setTimeout(function(){
        $('.des__body:eq('+e+')').css("max-height","600px");
      },120);
    },

    // 點擊空白處關閉
    handleClickaway(e) {
			const { clickaway } = this.$refs;
			if(!clickaway.contains(e.target)){
				this.showToggle = false;
			}
    },

    // 地圖效果
    clickDist(e) {
      if(e.target.parentNode !== null){
        // console.log(e.target.parentNode.id);
        if(e.target.parentNode.id.includes('vl-')){
          let focusItem = this.VoteList.filter(item =>item.ID==e.target.parentNode.id.split("-")[1]);
          this.VoteListDisplay = this.VoteList.filter(item =>item.ID!=e.target.parentNode.id.split("-")[1]);
          this.VoteListDisplay.unshift(focusItem[0]);

          $('html, body').stop(true, false).animate({
            scrollTop: $(".subNav").offset().top - 20
          }, 'slow');
        }
      }
    },

    // 票數排序
    sortList(m){
      let maxV = this.maxVote_Count;
      let maxR = this.maxRevVote;
      this.VoteListDisplay = this.VoteList.sort(function(a, b) {
        if(m == 'Total_LtoH'){          
          return ((a.Vote_Count*50)/maxV + (a.RevVote!==''?(a.RevVote*50)/maxR:0)) - ((b.Vote_Count*50)/maxV + (b.RevVote!==''?(b.RevVote*50)/maxR:0));
        } else if(m == 'Total_HtoL'){          
          return ((b.Vote_Count*50)/maxV + (b.RevVote!==''?(b.RevVote*50)/maxR:0)) - ((a.Vote_Count*50)/maxV + (a.RevVote!==''?(a.RevVote*50)/maxR:0));
        } else if(m == 'LtoH'){
          return (a.Vote_Count) - (b.Vote_Count);
        } else if(m == 'HtoL') {
          return (b.Vote_Count) - (a.Vote_Count);
        } else if(m == 'Num_HtoL') {
          if(a.PICID < b.PICID){return 1;} 
          if(a.PICID > b.PICID){return -1;}
          return 0;
        } else if(m == 'Num_LtoH') {
          if(a.PICID < b.PICID){return -1;} 
          if(a.PICID > b.PICID){return 1;}
          return 0;
        } 
      });
    },

    // 移除Html標籤
    removeHtml(str){
      return str.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g,"");
    },

    //數字加千分號
    toCurrency(num){
      var parts = num.toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return parts.join('.');
    },

    //分數加總
    toTotal(a, b){
      var parts = (a+b).toFixed(1);
      return parts;
    },
  }
}
</script>